import React from 'react';
import { ReactComponent as ArrowEmptyDownIcon } from '../../assets/arrow-empty-down.svg';
import { ReactComponent as ArrowEmptyRightIcon } from '../../assets/arrow-empty-right.svg';
import { Svg } from '../../common/components/Svg';

export function ToggleExpandIcon({
  isExpanded,
  toggleExpand,
  className,
}: {
  isExpanded: boolean;
  toggleExpand: () => void;
  className?: string;
}) {
  return (
    <div
      className={className}
      onClick={toggleExpand}
    >
      {isExpanded ? (
        <Svg
          size={16}
          value={ArrowEmptyDownIcon}
        />
      ) : (
        <Svg
          size={16}
          value={ArrowEmptyRightIcon}
        />
      )}
    </div>
  );
}
