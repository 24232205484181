import { MonthInterval, useTranslation } from '@elzeard/common-components';
import { differenceInWeeks, isAfter, isBefore, max, min } from 'date-fns';
import { last } from 'lodash';
import React, { Fragment } from 'react';
import styled from 'styled-components';
import { getWeekKey } from '../../outlet/utils';
import { timelineRow, weekWidth } from '../../table/style';
import { useSeriesState } from '../state-context';
import { SeriesProduct } from '../state-full';
import { EditablePurchaseResaleNeedCell } from './EditablePurchaseResaleNeedCell';
import { EditableSerieNeedCell } from './EditableSerieNeedCell';
import { displayValue } from './SeriesHeaderTable';
import {
  AvailableProductWeek,
  AvailableTargetWeek,
  ProductBackground,
  ProductBackgroundContainer,
  ProductWeekCell,
  TargetWeekCell,
} from './SeriesTimelineRows-style';

const Row = styled.tr`
  ${timelineRow}
`;

interface ProductBackgroundCellProps {
  product: SeriesProduct;
  time: MonthInterval;
  rowSpan: number;
}
function ProductBackgroundCell({ product, time, rowSpan }: ProductBackgroundCellProps) {
  const backgroundElements = product.availabilityPeriods.map((harvestPeriod) => {
    if (
      isBefore(harvestPeriod.end.firstDay, time.weeks[0].firstDay) ||
      isAfter(harvestPeriod.begin.firstDay, last(time.weeks).firstDay)
    ) {
      return null;
    }
    const begin = max([harvestPeriod.begin.firstDay, time.weeks[0].firstDay]);
    const end = min([harvestPeriod.end.firstDay, last(time.weeks).firstDay]);
    const nbWeeks = differenceInWeeks(end, begin) + 1;
    return (
      <ProductBackground
        key={harvestPeriod.begin.firstDay.getTime()}
        // className="content"
        style={{
          left: differenceInWeeks(begin, time.weeks[0].firstDay) * weekWidth + 1 + 'px',
          width: nbWeeks * weekWidth - 2 + 'px',
          backgroundColor: product.plantFamilyColors.veryFaded,
        }}
      >
        {/* <PlantPicture
          cropItineraryId={product.parentCropItineraryId}
          name={product.name}
        /> */}
      </ProductBackground>
    );
  });
  return <ProductBackgroundContainer>{backgroundElements}</ProductBackgroundContainer>;
}

export type SeriesTimelineRowsProps = {
  product: SeriesProduct;
  isEditingSingleProduct: boolean;
};

export function SeriesTimelineRows({ product, isEditingSingleProduct }: SeriesTimelineRowsProps) {
  const { t, formatNumber } = useTranslation();

  const { time, expandedSeriesRows, editedSerieCell, displayOutletNeeds, displayPurchaseResale } = useSeriesState();

  const mustDisplayPurchaseResale =
    displayPurchaseResale || (product.purchaseResale && !product.purchaseResale.isToBeDeleted);

  const isProductExpanded = isEditingSingleProduct || expandedSeriesRows[product.parentCropItineraryId];
  const isEditingProduct = editedSerieCell?.parentItineraryId === product.parentCropItineraryId;
  return (
    <>
      <Row>
        {time.weeks.map((week, weekIndex) => {
          const weekKey = getWeekKey(week);
          const isInPeriod = product.availabilityPeriods.some((period) => period.weeks[weekKey]);
          const seriesNeeds = product.weeklyNeedsFromAllSeries[weekKey];
          const outletNeeds = product.weeklyNeedsFromAllOutlets[weekKey];
          return (
            <ProductWeekCell
              key={weekKey}
              isInPeriod={isInPeriod}
              isFirst={weekIndex === 0}
            >
              {weekIndex === 0 && (
                <ProductBackgroundCell
                  product={product}
                  time={time}
                  rowSpan={displayOutletNeeds ? 2 : 1}
                />
              )}
              {isInPeriod || seriesNeeds ? (
                <AvailableProductWeek
                  total={Math.ceil(seriesNeeds || 0)}
                  target={outletNeeds || 0}
                  className="content"
                >
                  {displayValue({
                    value: seriesNeeds,
                    formatNumber,
                    t,
                  })}
                </AvailableProductWeek>
              ) : null}
            </ProductWeekCell>
          );
        })}
      </Row>
      {displayOutletNeeds && (
        <Row>
          {time.weeks.map((week) => {
            const weekKey = getWeekKey(week);
            const isInPeriod = product.availabilityPeriods.some((period) => period.weeks[weekKey]);
            const outletNeeds = product.weeklyNeedsFromAllOutlets[weekKey];
            return (
              <TargetWeekCell
                key={weekKey}
                isInPeriod={isInPeriod}
              >
                {(isInPeriod || outletNeeds) && (
                  <AvailableTargetWeek>
                    {displayValue({
                      value: outletNeeds,
                      formatNumber,
                      t,
                    })}
                  </AvailableTargetWeek>
                )}
              </TargetWeekCell>
            );
          })}
        </Row>
      )}
      {isProductExpanded && mustDisplayPurchaseResale && (
        <Row>
          {time.weeks.map((week) => {
            const weekKey = getWeekKey(week);
            const isEditing =
              isEditingProduct &&
              editedSerieCell.serieRowId === null &&
              'weekKey' in editedSerieCell &&
              editedSerieCell.weekKey === weekKey;
            return (
              <EditablePurchaseResaleNeedCell
                key={weekKey}
                weekKey={weekKey}
                product={product}
                isEditing={isEditing}
              />
            );
          })}
        </Row>
      )}
      {isProductExpanded &&
        product.series.map((serie) => {
          const isEditingSerie =
            isEditingProduct && editedSerieCell.serieRowId === serie.rowId && 'weekKey' in editedSerieCell;
          return (
            <Fragment key={serie.rowId}>
              <Row>
                {time.weeks.map((week, weekIndex) => {
                  const weekKey = getWeekKey(week);
                  const previousWeekKey = weekIndex === 0 ? null : getWeekKey(time.weeks[weekIndex - 1]);
                  const nextWeekKey =
                    weekIndex === time.weeks.length - 1 ? null : getWeekKey(time.weeks[weekIndex + 1]);
                  const isEditing = isEditingSerie && editedSerieCell.weekKey === weekKey;
                  return (
                    <EditableSerieNeedCell
                      key={weekKey}
                      weekKey={weekKey}
                      previousWeekKey={previousWeekKey}
                      nextWeekKey={nextWeekKey}
                      serie={serie}
                      isEditing={isEditing}
                    />
                  );
                })}
              </Row>
            </Fragment>
          );
        })}
    </>
  );
}
