import { useTranslation } from '@elzeard/common-components';
import React, { ComponentType, ReactNode } from 'react';
import { ReactComponent as AddIcon } from '../../../assets/icon-add.svg';
import { Button } from '../../../common/components/Button';
import { Token } from '../../../common/components/Token';
import { Setter } from '../../../common/utils/types';
import { translateHarvestUnitEnum } from '../../../shared-elzeard/constants/translate-constant';
import { ToggleExpandIcon } from '../../common/ToggleExpandIcon';
import { HeaderRow, MarginRightCell } from '../../table/style';
import { mainDisplayOptions } from '../state-build';
import { useOutletsCommands, useOutletsState } from '../state-context';
import { HeaderColumnName } from '../state-displayOptions';
import { Outlet, OutletProduct, OutletsPageState } from '../state-full';
import { HeaderCellMenuButton } from './HeaderCellMenu';
import {
  HeaderFooterTitleCell,
  HeaderFooterValueCell,
  OutletNameCell,
  ProductionNameCell,
  ProductionSubRowHeader,
  ValueCell,
} from './OutletHeaderRows-style';
import { columnDefinitions } from './OutletsHeaderTable';

export type OutletHeaderRowsProps = {
  columns: OutletsPageState['visibleColumns'];
  outlet: Outlet;
  isAllOutlets?: boolean;
  isEditingSingleOutlet: boolean;
  setShowCreateOutletForm: Setter<boolean | Outlet>;
};

export function OutletHeaderRows({
  outlet,
  isAllOutlets,
  columns,
  isEditingSingleOutlet,
  setShowCreateOutletForm,
}: OutletHeaderRowsProps) {
  const applyCommand = useOutletsCommands();
  const { expandedRows } = useOutletsState();
  const isOutletExpanded = isEditingSingleOutlet || expandedRows[outlet.rowId];
  const { t, formatNumber } = useTranslation();
  function toggleExpand() {
    applyCommand({
      type: 'toggleExpandRow',
      rowId: outlet.rowId,
    });
  }

  const outletName = isAllOutlets ? t('outlet:allOutlets.name') : outlet.name;
  return (
    <>
      {!isEditingSingleOutlet && (
        <HeaderRow>
          <OutletNameCell isEditingSingleOutlet={isEditingSingleOutlet}>
            <div className="content">
              {!isEditingSingleOutlet && (
                <ToggleExpandIcon
                  className="arrow-box"
                  toggleExpand={toggleExpand}
                  isExpanded={isOutletExpanded}
                />
              )}
              <Token $variant="primary">{outlet.products.length}</Token>
              <div
                className="title"
                title={outletName}
              >
                {outletName}
              </div>
              {!isEditingSingleOutlet && !isAllOutlets && (
                <div className="right-icons">
                  <Button
                    variant="CTA"
                    sizing="S"
                    isFilled
                    leftIcon={AddIcon}
                    onClick={() =>
                      applyCommand({
                        type: 'editOutletContent',
                        outletRowId: outlet.rowId,
                      })
                    }
                  />
                  <HeaderCellMenuButton>
                    <div onClick={() => setShowCreateOutletForm(outlet)}>
                      {t('outlet:header-table.outlet-menu.settings')}
                    </div>
                    <div
                      onClick={() =>
                        applyCommand({
                          type: 'askConfirmation',
                          command: {
                            type: 'disableOutlet',
                            outletRowId: outlet.rowId,
                          },
                          title: t('outlet:confirmation-modal.disable-outlet.title'),
                          details: t('outlet:confirmation-modal.disable-outlet.details'),
                        })
                      }
                    >
                      {t('outlet:header-table.outlet-menu.disable')}
                    </div>
                    <div
                      onClick={() =>
                        applyCommand({
                          type: 'askConfirmation',
                          command: {
                            type: 'removeOutlet',
                            outletRowId: outlet.rowId,
                          },
                          title: t('outlet:confirmation-modal.delete-outlet.title'),
                          details: t('outlet:confirmation-modal.delete-outlet.details'),
                        })
                      }
                    >
                      {t('outlet:header-table.outlet-menu.delete')}
                    </div>
                  </HeaderCellMenuButton>
                </div>
              )}
            </div>
          </OutletNameCell>
          <ValueCell>
            {columnDefinitions.totalSales.value({
              value: outlet.totalSales,
              formatNumber,
              t,
            })}
          </ValueCell>
          <MarginRightCell />
        </HeaderRow>
      )}
      {isOutletExpanded &&
        outlet.products.map((product) => (
          <ProductRows
            key={product.rowId}
            product={product}
            columns={columns}
            isEditingSingleOutlet={isEditingSingleOutlet}
            LeftIcon={NumberOfSeriesToken}
          />
        ))}
    </>
  );
}

interface ProductRowsProps<T extends OutletProduct> {
  product: T;
  LeftIcon: ComponentType<{ product: T }>;
  isEditingSingleOutlet: boolean;
  columns: OutletsPageState['visibleColumns'];
}

export function ProductRows<T extends OutletProduct>({
  product,
  isEditingSingleOutlet,
  columns,
  LeftIcon,
}: ProductRowsProps<T>) {
  const { t, formatNumber } = useTranslation();
  const applyCommand = useOutletsCommands();
  const { expandedRows, mainDisplayOption } = useOutletsState();
  const isProductExpanded = expandedRows[product.rowId];
  function toggleExpand() {
    applyCommand({
      type: 'toggleExpandRow',
      rowId: product.rowId,
    });
  }
  return (
    <>
      <HeaderRow>
        <ProductionNameCell
          isExpanded={isProductExpanded}
          isEditingSingleOutlet={isEditingSingleOutlet}
        >
          <div className="content">
            <ToggleExpandIcon
              className="arrow-box"
              toggleExpand={toggleExpand}
              isExpanded={isProductExpanded}
            />
            <LeftIcon product={product} />
            <div
              className="title"
              title={product.name}
            >
              {product.name}
            </div>
            {/* <div className="right-icons"></div> */}
          </div>
        </ProductionNameCell>
        {columns.map((column) => (
          <ValueCell key={column}>
            {
              columnDefinitions[column].value({
                value: product[column],
                formatNumber,
                t,
              } as never) // WTF ?
            }
          </ValueCell>
        ))}
        <MarginRightCell />
      </HeaderRow>
      {isProductExpanded &&
        mainDisplayOptions.map((displayOption) => {
          if (displayOption === mainDisplayOption) {
            return null;
          }
          return (
            <HeaderRow key={displayOption}>
              <ProductionSubRowHeader>
                <div className="content">
                  <div className="title">
                    {t(`outlet:header-table.product-sub-row.${displayOption}.name`)}
                    <div className="unit">
                      (
                      {t(`outlet:header-table.product-sub-row.${displayOption}.unit`, {
                        quantityUnit: translateHarvestUnitEnum(t, product.quantityUnit, 'short'),
                      })}
                      )
                    </div>
                  </div>
                </div>
              </ProductionSubRowHeader>
              {columns.map((column) => (
                <ValueCell key={column}></ValueCell>
              ))}
              <MarginRightCell />
            </HeaderRow>
          );
        })}
    </>
  );
}

export function HeaderFooterRow({
  title,
  value,
  columns,
}: {
  title: ReactNode;
  value: ReactNode;
  columns: Array<HeaderColumnName>;
}) {
  return (
    <tr>
      <HeaderFooterTitleCell colSpan={columns.length}>{title}</HeaderFooterTitleCell>
      <HeaderFooterValueCell>{value}</HeaderFooterValueCell>
      <MarginRightCell />
    </tr>
  );
}

function NumberOfSeriesToken({ product }: { product: OutletProduct }) {
  return <Token $variant="secondary">{product.numberOfSeries}</Token>;
}
