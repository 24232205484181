import { useTranslation } from '@elzeard/common-components';
import { NumberMap } from '@elzeard/common-planning';
import React, { ComponentType, Dispatch, ReactNode, useMemo } from 'react';
import { AllOutletCommands } from '../commands';
import { useOutletsCommands } from '../state-context';
import { OutletProduct } from '../state-full';
import { EditedCell } from './EditedCell';
import { displayValue } from './OutletsHeaderTable';
import { ProductWeekCellProps } from './OutletTimelineRows-style';

interface EditableProductCellProps {
  outletRowId: string;
  weekKey: string;
  product: OutletProduct;
  propertyName: keyof OutletProduct;
  isDisabled: boolean;
  isEditing: boolean;
  isSubrow?: boolean;
  isLastRow?: boolean;
  previousWeekKey?: string;
  nextWeekKey?: string;
  background?: ReactNode;
  Container: ComponentType<ProductWeekCellProps>;
}

export function EditableProductCell({
  product,
  propertyName,
  weekKey,
  outletRowId,
  isDisabled,
  isEditing,
  isSubrow,
  isLastRow,
  nextWeekKey,
  previousWeekKey,
  background = null,
  Container,
}: EditableProductCellProps) {
  const { t, formatNumber } = useTranslation();
  const applyCommand = useOutletsCommands();
  const isProductAvailable = product.availabilityPeriods.some((period) => period.weeks[weekKey]);
  const productProperty = product[propertyName] as NumberMap;
  const { hasValue, value, formattedValue } = (() => {
    if (propertyName === 'weeklyPrices') {
      const value = productProperty[weekKey] ?? product.defaultUnitPrice;
      return {
        hasValue: isProductAvailable && !isDisabled,
        value,
        formattedValue: value && formatNumber(value, 0, 2),
      };
    } else {
      const value = productProperty[weekKey];
      return {
        hasValue: weekKey in productProperty,
        value,
        formattedValue: displayValue({
          value,
          t,
          formatNumber,
        }),
      };
    }
  })();
  const isFirstCell =
    isSubrow &&
    isProductAvailable &&
    (!previousWeekKey || !product.availabilityPeriods.some((period) => period.weeks[previousWeekKey]));
  const isLastCell =
    isSubrow &&
    isProductAvailable &&
    (!nextWeekKey || !product.availabilityPeriods.some((period) => period.weeks[nextWeekKey]));

  return (
    <Container
      key={weekKey}
      $isAvailable={isProductAvailable}
      $hasValue={hasValue}
      $isDisabled={isDisabled}
      $isEditing={!isDisabled && isEditing}
      $isSubrow={isSubrow}
      $isLastRow={isLastRow}
      $isFirstCell={isFirstCell}
      $isLastCell={isLastCell}
      $isError={value < 0}
      $plantFamilyColors={product.plantFamilyColors}
      onClick={
        isDisabled
          ? undefined
          : () =>
              applyCommand({
                type: 'setEditedCell',
                outletRowId,
                parentCropItineraryId: product.parentCropItineraryId,
                weekKey,
                editedProperty: propertyName,
              })
      }
    >
      {background}
      {!isDisabled && isEditing ? (
        <EditedProductCell
          outletRowId={outletRowId}
          parentItineraryId={product.parentCropItineraryId}
          propertyName={propertyName}
          weekKey={weekKey}
          value={value}
          applyCommand={applyCommand}
        />
      ) : (
        <div
          className="content"
          title={value ? formatNumber(value) : undefined}
        >
          {isProductAvailable || value ? formattedValue ?? '-' : ''}
        </div>
      )}
    </Container>
  );
}
interface EditedProductCellProps {
  outletRowId: string;
  parentItineraryId: string;
  propertyName: keyof OutletProduct;
  weekKey: string;
  value: number;
  applyCommand: Dispatch<AllOutletCommands>;
}
function EditedProductCell({
  outletRowId,
  parentItineraryId,
  propertyName,
  weekKey,
  value,
  applyCommand,
}: EditedProductCellProps) {
  const { quitEdition, editProperty } = useMemo(() => {
    return {
      quitEdition: () =>
        applyCommand({
          type: 'setEditedCell',
          outletRowId: null,
          parentCropItineraryId: null,
          editedProperty: null,
          weekKey: null,
        }),
      editProperty: (value: number) =>
        applyCommand({
          type: 'editProductProperty',
          outletRowId,
          parentItineraryId,
          propertyName,
          weekKey,
          value,
        }),
    };
  }, [applyCommand, parentItineraryId, outletRowId, propertyName, weekKey]);

  return (
    <EditedCell
      value={value || null}
      quitEdition={quitEdition}
      editProperty={editProperty}
    />
  );
}
