import { useTranslation } from '@elzeard/common-components';
import React, { ComponentType, useState } from 'react';
import { ReactComponent as ArrowStrokeRightIcon } from '../../../assets/arrow-strokebig-right.svg';
import { ReactComponent as PurchaseResaleIcon } from '../../../assets/culture-mode-achat-revente.svg';
import { ReactComponent as EditIcon } from '../../../assets/icon-edit.svg';
import { Button } from '../../../common/components/Button';
import { TextInput } from '../../../common/components/field/InputField';
import { ConfirmationModal } from '../../../common/components/modal/ConfirmationModal';
import { Svg } from '../../../common/components/Svg';
import { Token } from '../../../common/components/Token';
import { ToggleExpandIcon } from '../../common/ToggleExpandIcon';
import { HeaderCellMenuButton } from '../../outlet/components/HeaderCellMenu';
import { HeaderRow, MarginRightCell } from '../../table/style';
import { useSeriesCommands, useSeriesState } from '../state-context';
import { ProductSerie, SeriesProduct } from '../state-full';
import { SerieIconHeaderCell } from './SerieIconHeaderCell';
import { SerieNumberHeaderCell } from './SerieNumberHeaderCell';
import { OutletNeedsHeader, ProductNameCell, SerieNameCell, SerieValueCell } from './SeriesHeaderRows-style';
import {
  HeaderCellProps,
  HeaderFieldType,
  productsColumnDefinitions,
  SerieHeaderColumnName,
  SeriesColumnDefinition,
  seriesColumnDefinitions,
} from './SeriesHeaderTable';

export type SeriesHeaderRowsProps = {
  product: SeriesProduct;
  isEditingSingleProduct: boolean;
  columns: Array<SerieHeaderColumnName>;
};

export function SeriesHeaderRows({ columns, isEditingSingleProduct, product }: SeriesHeaderRowsProps) {
  const { t, formatNumber } = useTranslation();

  const applyCommand = useSeriesCommands();
  const { expandedSeriesRows, displayOutletNeeds, displayPurchaseResale } = useSeriesState();

  const mustDisplayPurchaseResale =
    displayPurchaseResale || (product.purchaseResale && !product.purchaseResale.isToBeDeleted);

  function toggleExpand() {
    applyCommand({
      type: 'toggleExpandSeriesRow',
      rowId: product.parentCropItineraryId,
    });
  }
  const isProductExpanded = isEditingSingleProduct || expandedSeriesRows[product.parentCropItineraryId];
  const columnDefinitions = isEditingSingleProduct ? seriesColumnDefinitions : productsColumnDefinitions;

  return (
    <>
      <HeaderRow>
        <ProductNameCell
          // colSpan={columns.length}
          isEditingSingleProduct={isEditingSingleProduct}
        >
          <div className="content">
            {!isEditingSingleProduct && (
              <ToggleExpandIcon
                className="arrow-box"
                toggleExpand={toggleExpand}
                isExpanded={isProductExpanded}
              />
            )}
            <Token $variant="primary">{product.series.length}</Token>
            <div
              className="title"
              title={product.name}
            >
              {product.name}
            </div>
            {!isEditingSingleProduct && (
              <div className="right-icons">
                {!isEditingSingleProduct && (
                  <Button
                    variant="CTA"
                    sizing="S"
                    isFilled
                    leftIcon={EditIcon}
                    onClick={() =>
                      applyCommand({
                        type: 'editProductSeries',
                        editedSeriesProductRowId: product.parentCropItineraryId,
                      })
                    }
                  />
                )}
              </div>
            )}
          </div>
        </ProductNameCell>
        {columns.map((column) => (
          <SerieValueCell key={column}>
            {columnDefinitions[column].value({
              value: product[column],
              formatNumber,
              t,
              serieOrProduct: product,
            })}
          </SerieValueCell>
        ))}
        <MarginRightCell />
      </HeaderRow>
      {displayOutletNeeds &&
        (isEditingSingleProduct ? (
          <HeaderRow>
            <OutletNeedsHeader
              colSpan={columns.length}
              isEditingSingleProduct
            >
              <div className="content">
                <Svg
                  value={ArrowStrokeRightIcon}
                  className="arrow"
                />
                {t('series:header-table.no-selection.outlets-needs')}
              </div>
            </OutletNeedsHeader>
            <SerieValueCell>
              {columnDefinitions.quantity.value({
                value: product.outletsTotal,
                formatNumber,
                t,
              })}
            </SerieValueCell>
            <MarginRightCell />
          </HeaderRow>
        ) : (
          <HeaderRow>
            <OutletNeedsHeader
              colSpan={columns.length - 1}
              isEditingSingleProduct={false}
            >
              <div className="content">
                <Svg
                  value={ArrowStrokeRightIcon}
                  className="arrow"
                />
                {t('series:header-table.no-selection.outlets-needs')}
              </div>
            </OutletNeedsHeader>
            <SerieValueCell>
              {columnDefinitions.quantity.value({
                value: product.outletsTotal,
                formatNumber,
                t,
              })}
            </SerieValueCell>
            <SerieValueCell>
              {columnDefinitions.harvestQuantityUnit.value({
                value: product.harvestQuantityUnit,
                formatNumber,
                t,
              })}
            </SerieValueCell>
            <MarginRightCell />
          </HeaderRow>
        ))}
      {isProductExpanded &&
        mustDisplayPurchaseResale &&
        (isEditingSingleProduct ? (
          <HeaderRow>
            <OutletNeedsHeader
              colSpan={columns.length}
              isEditingSingleProduct
            >
              <div className="content">
                <Svg
                  value={PurchaseResaleIcon}
                  className="arrow"
                />
                {t('series:header-table.purchase-resale')}
              </div>
            </OutletNeedsHeader>
            <SerieValueCell>
              {columnDefinitions.quantity.value({
                value: product.purchaseResaleTotal,
                formatNumber,
                t,
              })}
            </SerieValueCell>
            <MarginRightCell />
          </HeaderRow>
        ) : (
          <HeaderRow>
            <OutletNeedsHeader
              colSpan={columns.length - 1}
              isEditingSingleProduct={false}
            >
              <div className="content">
                <Svg
                  value={PurchaseResaleIcon}
                  className="arrow"
                />
                {t('series:header-table.purchase-resale')}
              </div>
            </OutletNeedsHeader>
            <SerieValueCell>
              {columnDefinitions.quantity.value({
                value: product.purchaseResaleTotal,
                formatNumber,
                t,
              })}
            </SerieValueCell>
            <SerieValueCell>
              {columnDefinitions.harvestQuantityUnit.value({
                value: product.harvestQuantityUnit,
                formatNumber,
                t,
              })}
            </SerieValueCell>
            <MarginRightCell />
          </HeaderRow>
        ))}
      {isProductExpanded &&
        product.series.map((serie, serieIndex) => (
          <SerieRows
            key={serie.rowId}
            isEditingSingleProduct={isEditingSingleProduct}
            columns={columns}
            serie={serie}
            serieNumber={serieIndex + 1}
            // numberOfSeries: product.series.length,
          />
        ))}
    </>
  );
}

const headerCells: Record<HeaderFieldType, ComponentType<HeaderCellProps>> = {
  icon: SerieIconHeaderCell,
  text: null,
  integer: SerieNumberHeaderCell,
  percent: SerieNumberHeaderCell,
  week: SerieNumberHeaderCell,
};

function SerieRows({
  serie,
  columns,
  isEditingSingleProduct,
  // numberOfSeries,
  serieNumber,
}: Omit<SeriesHeaderRowsProps, 'editedSerieCell' | 'product'> & {
  serie: ProductSerie;
  serieNumber: number;
  // numberOfSeries: number;
}) {
  const { t, formatNumber } = useTranslation();

  const applyCommand = useSeriesCommands();
  const { editedSerieCell } = useSeriesState();

  const columnDefinitions = isEditingSingleProduct ? seriesColumnDefinitions : productsColumnDefinitions;
  const isEditingSerie = editedSerieCell && editedSerieCell.serieRowId === serie.rowId;

  const [openRenameSerieModal, setOpenRenameSerieModal] = useState(false);

  return (
    <>
      <HeaderRow>
        <SerieNameCell isEditingSingleProduct={isEditingSingleProduct}>
          <div className="content">
            <div className="title">
              {serie.customName || `${t('series:header-table.serie-sub-row.name-cell.serie')} ${serieNumber}`}
            </div>
            <div className="right-icons">
              {serie.isSelected && (
                <HeaderCellMenuButton>
                  <div
                    onClick={() =>
                      applyCommand({
                        type: 'duplicateSerie',
                        parentItineraryId: serie.parentItineraryId,
                        productRowId: serie.productRowId,
                      })
                    }
                  >
                    {t('series:header-table.serie-menu.duplicate')}
                  </div>
                  <div
                    onClick={() =>
                      applyCommand({
                        type: 'askSeriesCommandConfirmation',
                        command: {
                          type: 'removeSerie',
                          parentItineraryId: serie.parentItineraryId,
                          productRowId: serie.productRowId,
                        },
                        title: t('series:confirmation-modal.delete-serie.title'),
                        details: t('series:confirmation-modal.delete-serie.details'),
                      })
                    }
                  >
                    {t('series:header-table.serie-menu.delete')}
                  </div>
                  <div onClick={() => setOpenRenameSerieModal(true)}>{t('series:header-table.serie-menu.rename')}</div>
                </HeaderCellMenuButton>
              )}
            </div>
          </div>
        </SerieNameCell>
        {columns.map((column) => {
          const columnDefinition = columnDefinitions[column] as SeriesColumnDefinition<SerieHeaderColumnName>;
          // const isEditable = 'editor' in columnDefinition;
          const isEditingCell =
            isEditingSerie && 'editedProperty' in editedSerieCell && editedSerieCell.editedProperty === column;
          if (columnDefinition.isEditable) {
            const Editor = headerCells[columnDefinition.type];
            return (
              <Editor
                key={column}
                applyCommand={applyCommand}
                fieldDefinition={columnDefinition}
                isEditing={isEditingCell}
                propertyName={column}
                serie={serie}
              />
            );
          } else {
            return (
              <SerieValueCell key={column}>
                {
                  columnDefinition.value({
                    value: serie[column],
                    formatNumber,
                    t,
                    serieOrProduct: serie,
                  } as never) // WTF ?
                }
              </SerieValueCell>
            );
          }
        })}
        <MarginRightCell />
      </HeaderRow>
      {openRenameSerieModal && (
        <RenameSerieModal
          onClose={() => setOpenRenameSerieModal(false)}
          onConfirm={(newName) =>
            applyCommand({
              type: 'renameSerie',
              childItineraryId: serie.childItineraryId,
              parentItineraryId: serie.parentItineraryId,
              productRowId: serie.productRowId,
              newName,
            })
          }
          serie={serie}
        />
      )}
    </>
  );
}

function RenameSerieModal({
  onClose,
  onConfirm,
  serie,
}: {
  serie: ProductSerie;
  onClose: () => void;
  onConfirm: (newName: string) => void;
}) {
  const { t } = useTranslation();

  const [name, setName] = useState(serie.customName || '');

  return (
    <ConfirmationModal
      title={t('series:header-table.serie-menu.rename')}
      onClose={onClose}
      onCancel={onClose}
      onConfirm={() => {
        onConfirm(name);
        onClose();
      }}
      details={
        <TextInput
          value={name}
          onValueChange={setName}
        />
      }
    />
  );
}
