import { Tooltip, useTranslation } from '@elzeard/common-components';
import React, { ReactNode } from 'react';
import styled from 'styled-components';
import { ReactComponent as ErrorIcon } from '../../../assets/icon-error.svg';
import { ReactComponent as ValidIcon } from '../../../assets/icon-validate.svg';
import { ReactComponent as WarningIcon } from '../../../assets/icon-warning.svg';
import { Svg } from '../../../common/components/Svg';
import { colorStyle } from '../../../common/style/colors';
import { ToggleExpandIcon } from '../../common/ToggleExpandIcon';
import { HeaderRow, MarginRightCell } from '../../table/style';
import { useOutletsCommands, useOutletsState } from '../state-context';
import { OutletsPageState, UNALLOCATED_NEEDS_ROW_ID, UnallocatedNeedsProduct } from '../state-full';
import { ProductRows } from './OutletHeaderRows';
import { OutletNameCell, ValueCell } from './OutletHeaderRows-style';

enum UnallocatedProductStatus {
  Warning = 'warning',
  Error = 'error',
  Ok = 'ok',
}

export function UnallocatedHeaderRows({ columns }: { columns: OutletsPageState['visibleColumns'] }) {
  const { t } = useTranslation();

  const rowId = UNALLOCATED_NEEDS_ROW_ID;
  const applyCommand = useOutletsCommands();
  const { expandedRows, unallocated } = useOutletsState();

  const isOutletExpanded = expandedRows[rowId];

  function toggleExpand() {
    applyCommand({
      type: 'toggleExpandRow',
      rowId: rowId,
    });
  }

  const outletName = t('outlet:unallocated-needs.name');
  return (
    <>
      <HeaderRow>
        <OutletNameCell isEditingSingleOutlet={false}>
          <div className="content">
            <ToggleExpandIcon
              className="arrow-box"
              toggleExpand={toggleExpand}
              isExpanded={isOutletExpanded}
            />
            <UnallocatedStatusIcon
              status={
                unallocated.numberOfProductsWithUnsufficientNeeds > 0
                  ? UnallocatedProductStatus.Error
                  : UnallocatedProductStatus.Ok
              }
              tooltip={t('outlet:unallocated-needs.global-unsufficient-needs')}
            />
            <div
              className="title"
              title={outletName}
            >
              {outletName}
            </div>
          </div>
        </OutletNameCell>
        <ValueCell>-</ValueCell>
        <MarginRightCell />
      </HeaderRow>
      {isOutletExpanded &&
        unallocated.products.map((product) => (
          <ProductRows
            key={product.rowId}
            product={product}
            columns={columns}
            isEditingSingleOutlet={false}
            LeftIcon={({ product }) => <ProductUnallocatedNeedsIcon product={product} />}
          />
        ))}
    </>
  );
}

function getUnallocatedProductStatus(product: UnallocatedNeedsProduct) {
  return product.hasUnsufficientSeriesNeeds
    ? product.seriesTotalQuantity >= product.allOutletsTotalNeeds
      ? UnallocatedProductStatus.Warning
      : UnallocatedProductStatus.Error
    : UnallocatedProductStatus.Ok;
}

const statusIcon = {
  [UnallocatedProductStatus.Warning]: WarningIcon,
  [UnallocatedProductStatus.Error]: ErrorIcon,
  [UnallocatedProductStatus.Ok]: ValidIcon,
};
const statusColor = {
  [UnallocatedProductStatus.Warning]: colorStyle.error['3'],
  [UnallocatedProductStatus.Error]: colorStyle.error['1'],
  [UnallocatedProductStatus.Ok]: colorStyle.validate['1'],
};

function ProductUnallocatedNeedsIcon({ product }: { product: UnallocatedNeedsProduct }) {
  const { t } = useTranslation();
  const status = getUnallocatedProductStatus(product);
  return (
    <UnallocatedStatusIcon
      status={status}
      tooltip={t('outlet:unallocated-needs.product-status-tooltip.' + status)}
    />
  );
}

function UnallocatedStatusIcon({ status, tooltip }: { status: UnallocatedProductStatus; tooltip: ReactNode }) {
  return status === UnallocatedProductStatus.Ok ? (
    <StyledStatusIcon
      size={16}
      value={ValidIcon}
      $status={status}
    />
  ) : (
    <Tooltip
      content={tooltip}
      direction="right"
    >
      <StyledStatusIcon
        size={16}
        value={statusIcon[status]}
        $status={status}
      />
    </Tooltip>
  );
}

const StyledStatusIcon = styled(Svg)<{ $status: UnallocatedProductStatus }>`
  margin-right: 12px;
  color: ${({ $status }) => statusColor[$status]};
`;
