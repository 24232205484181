import React, { Fragment } from 'react';
import styled from 'styled-components';
import { timelineRow } from '../../table/style';
import { getProductPropertyName, mainDisplayOptions } from '../state-build';
import { useOutletsState } from '../state-context';
import { UNALLOCATED_NEEDS_ROW_ID } from '../state-full';
import { getWeekKey } from '../utils';
import { EditableProductCell } from './EditableProductCell';
import { BackgroundCell } from './OutletTimelineRows';
import { OutletWeekCell, ProductWeekCell, SubProductWeekCell } from './OutletTimelineRows-style';

const Row = styled.tr`
  ${timelineRow}
`;

export function UnallocatedTimelineRows() {
  const rowId = UNALLOCATED_NEEDS_ROW_ID;

  const { time, expandedRows, mainDisplayOption, editedCell, unallocated } = useOutletsState();

  const isOutletExpanded = expandedRows[rowId];

  return (
    <>
      <Row>
        {time.weeks.map((week) => {
          const weekKey = getWeekKey(week);
          return (
            <OutletWeekCell key={weekKey}>
              <div>-</div>
            </OutletWeekCell>
          );
        })}
      </Row>
      {isOutletExpanded &&
        unallocated.products.map((product) => {
          const isProductExpanded = expandedRows[product.rowId];
          const isEditingProduct = false;
          return (
            <Fragment key={product.rowId}>
              <Row>
                {time.weeks.map((week, weekIndex) => {
                  const weekKey = getWeekKey(week);
                  const propertyName = getProductPropertyName(mainDisplayOption);
                  const isEditing =
                    isEditingProduct && editedCell.weekKey === weekKey && editedCell.editedProperty === propertyName;
                  return (
                    <EditableProductCell
                      key={weekKey}
                      background={
                        weekIndex === 0 ? (
                          <BackgroundCell
                            product={product}
                            time={time}
                            isExpanded={isProductExpanded}
                          />
                        ) : null
                      }
                      weekKey={weekKey}
                      product={product}
                      propertyName={propertyName}
                      isDisabled={true}
                      isEditing={isEditing}
                      outletRowId={rowId}
                      Container={ProductWeekCell}
                    />
                  );
                })}
              </Row>
              {isProductExpanded &&
                mainDisplayOptions.map((displayOption, displayOptionIndex) => {
                  if (displayOption === mainDisplayOption) {
                    return null;
                  }
                  return (
                    <Row key={displayOption}>
                      {time.weeks.map((week, weekIndex) => {
                        const weekKey = getWeekKey(week);
                        const propertyName = getProductPropertyName(displayOption);
                        const isEditing =
                          isEditingProduct &&
                          editedCell.weekKey === weekKey &&
                          editedCell.editedProperty === propertyName;
                        const previousWeekKey = weekIndex === 0 ? null : getWeekKey(time.weeks[weekIndex - 1]);
                        const nextWeekKey =
                          weekIndex === time.weeks.length - 1 ? null : getWeekKey(time.weeks[weekIndex + 1]);
                        return (
                          <EditableProductCell
                            key={weekKey}
                            weekKey={weekKey}
                            product={product}
                            propertyName={propertyName}
                            isDisabled={true}
                            isEditing={isEditing}
                            isSubrow
                            isLastRow={displayOptionIndex === mainDisplayOptions.length - 1}
                            previousWeekKey={previousWeekKey}
                            nextWeekKey={nextWeekKey}
                            outletRowId={rowId}
                            Container={SubProductWeekCell}
                          />
                        );
                      })}
                    </Row>
                  );
                })}
            </Fragment>
          );
        })}
    </>
  );
}
