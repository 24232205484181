import { MonthInterval, useTranslation } from '@elzeard/common-components';
import { differenceInWeeks, isAfter, isBefore, max, min } from 'date-fns';
import { last } from 'lodash';
import React, { Fragment } from 'react';
import styled from 'styled-components';
import { PlantPicture } from '../../common/PlantPicture';
import { timelineRow, weekWidth } from '../../table/style';
import { getProductPropertyName, mainDisplayOptions } from '../state-build';
import { useOutletsState } from '../state-context';
import { Outlet, OutletProduct } from '../state-full';
import { getWeekKey } from '../utils';
import { EditableProductCell } from './EditableProductCell';
import { displayValue } from './OutletsHeaderTable';
import {
  BackgroundCellContainer,
  OutletWeekCell,
  ProductWeekCell,
  SubProductWeekCell,
} from './OutletTimelineRows-style';

const Row = styled.tr`
  ${timelineRow}
`;

interface BackgroundCellProps {
  product: OutletProduct;
  time: MonthInterval;
  isExpanded: boolean;
}
export function BackgroundCell({ product, time, isExpanded }: BackgroundCellProps) {
  const backgroundElements = product.availabilityPeriods.map((harvestPeriod) => {
    if (
      isBefore(harvestPeriod.end.firstDay, time.weeks[0].firstDay) ||
      isAfter(harvestPeriod.begin.firstDay, last(time.weeks).firstDay)
    ) {
      return null;
    }
    const begin = max([harvestPeriod.begin.firstDay, time.weeks[0].firstDay]);
    const end = min([harvestPeriod.end.firstDay, last(time.weeks).firstDay]);
    const nbWeeks = differenceInWeeks(end, begin) + 1;
    return (
      <div
        key={harvestPeriod.begin.firstDay.getTime()}
        className="content"
        style={{
          left: differenceInWeeks(begin, time.weeks[0].firstDay) * weekWidth + 'px',
          width: nbWeeks * weekWidth + 'px',
          backgroundColor: product.plantFamilyColors.veryFaded,
        }}
      >
        <PlantPicture
          cropItineraryId={product.referenceParentItineraryId || product.parentCropItineraryId}
          name={product.name}
        />
      </div>
    );
  });
  return <BackgroundCellContainer $isExpanded={isExpanded}>{backgroundElements}</BackgroundCellContainer>;
}

export type OutletTimelineRowsProps = {
  outlet: Outlet;
  isAllOutlets?: boolean;
  isEditingSingleOutlet: boolean;
};

export function OutletTimelineRows({ outlet, isAllOutlets, isEditingSingleOutlet }: OutletTimelineRowsProps) {
  const { t, formatNumber } = useTranslation();

  const { time, expandedRows, mainDisplayOption, editedCell } = useOutletsState();

  const isOutletExpanded = isEditingSingleOutlet || expandedRows[outlet.rowId];
  const isEditingOutlet = editedCell?.outletRowId === outlet.rowId;
  return (
    <>
      {!isEditingSingleOutlet && (
        <Row>
          {time.weeks.map((week) => {
            const weekKey = getWeekKey(week);
            return (
              <OutletWeekCell key={weekKey}>
                <div>
                  {mainDisplayOption === 'totalSales'
                    ? displayValue({
                        value: outlet.weeklySales[weekKey],
                        formatNumber,
                        t,
                      })
                    : '-'}
                </div>
              </OutletWeekCell>
            );
          })}
        </Row>
      )}
      {isOutletExpanded &&
        outlet.products.map((product) => {
          const isProductExpanded = expandedRows[product.rowId];
          const isEditingProduct =
            isEditingOutlet && editedCell.parentCropItineraryId === product.parentCropItineraryId;
          return (
            <Fragment key={product.rowId}>
              <Row>
                {time.weeks.map((week, weekIndex) => {
                  const weekKey = getWeekKey(week);
                  const propertyName = getProductPropertyName(mainDisplayOption);
                  const isEditing =
                    isEditingProduct && editedCell.weekKey === weekKey && editedCell.editedProperty === propertyName;
                  return (
                    <EditableProductCell
                      key={weekKey}
                      background={
                        weekIndex === 0 ? (
                          <BackgroundCell
                            product={product}
                            time={time}
                            isExpanded={isProductExpanded}
                          />
                        ) : null
                      }
                      weekKey={weekKey}
                      product={product}
                      propertyName={propertyName}
                      isDisabled={isAllOutlets}
                      isEditing={isEditing}
                      outletRowId={outlet.rowId}
                      Container={ProductWeekCell}
                    />
                  );
                })}
              </Row>
              {isProductExpanded &&
                mainDisplayOptions.map((displayOption, displayOptionIndex) => {
                  if (displayOption === mainDisplayOption) {
                    return null;
                  }
                  return (
                    <Row key={displayOption}>
                      {time.weeks.map((week, weekIndex) => {
                        const weekKey = getWeekKey(week);
                        const propertyName = getProductPropertyName(displayOption);
                        const isEditing =
                          isEditingProduct &&
                          editedCell.weekKey === weekKey &&
                          editedCell.editedProperty === propertyName;
                        const previousWeekKey = weekIndex === 0 ? null : getWeekKey(time.weeks[weekIndex - 1]);
                        const nextWeekKey =
                          weekIndex === time.weeks.length - 1 ? null : getWeekKey(time.weeks[weekIndex + 1]);
                        return (
                          <EditableProductCell
                            key={weekKey}
                            weekKey={weekKey}
                            product={product}
                            propertyName={propertyName}
                            isDisabled={isAllOutlets}
                            isEditing={isEditing}
                            isSubrow
                            isLastRow={displayOptionIndex === mainDisplayOptions.length - 1}
                            previousWeekKey={previousWeekKey}
                            nextWeekKey={nextWeekKey}
                            outletRowId={outlet.rowId}
                            Container={SubProductWeekCell}
                          />
                        );
                      })}
                    </Row>
                  );
                })}
            </Fragment>
          );
        })}
    </>
  );
}
